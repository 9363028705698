<template>
  <div>
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title
          >System Response<v-icon class="ml-2">mdi-robot</v-icon></v-card-title
        >
        <v-card-text>
          <p>{{ response }}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog = $emit('close')"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["response"],
  data() {
    return {
      dialog: true,
    };
  },
};
</script>

<style lang="scss" scoped></style>
